import moment from 'moment'
import Utils from '../../utils/Utils'

const PANEL_URL = process.env.VUE_APP_API_PANEL;
const BROADCAST_URL = process.env.VUE_APP_API_BROADCAST;
const WHATSAPP_URL = process.env.VUE_APP_API_WHATSAPP;
const LIVE_SOCKET = process.env.VUE_APP_SOCKET_LIVE;
const BROADCAST_SOCKET = process.env.VUE_APP_SOCKET_BROADCAST;
const FACEBOOK_URL = process.env.VUE_APP_API_FACEBOOK;
const MAIN_URL = process.env.VUE_APP_API;
const PANEL_SOCKET = process.env.VUE_APP_SOCKET_PANEL;

export default {
  asyncForEach: async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array)
    }
  },
  version: {
    number: '1.0.52',
    build: '2042',
    time: '10/12/2021 16:54'
  },
  api: {
    api: MAIN_URL,
    apiPanel: PANEL_URL,
    apiPanelLive: PANEL_SOCKET,
    apiBroadcast: BROADCAST_SOCKET,
    broadcast: BROADCAST_URL,
    apiWhatsapp: WHATSAPP_URL,
    apiFacebook: FACEBOOK_URL,
    live: LIVE_SOCKET,
    devLive: LIVE_SOCKET
  },
  alarm: {
    isActive: false,
    tolerance: null,
    display: '',
    counter: null,
    dialogYellow: false,
    dialogRed: false
  },
  lostConnection: {
    isActive: false,
  },
  breadcrumbs: {
    title: '',
    help: null,
    paths: []
  },
  advancedSearch: {
    match: {
      period: 7,
      cdt: {
        $gte: moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DDTHH:mm:ss.sssZ'),
        $lte: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss.sssZ')
      },
      monetaryValue: {
        $gte: '',
        $lte: ''
      },
      ih: {
        $in: []
      },
      hb: 'Todos',
      dialogue: 'Todos',
      tags: [],
      rating: [],
      live: 5,
      attendants: [],
      departments: [],
      cIdentifiers: [],
      cIdentifierFill: true,
      cResponse: '',
      cResponseIdentifiers: [],
      cFinished: 'Todas',
      faqCategory: 'all',
      r: 'all'
    },
    options: {
      chatbots: [],
      identifiers: [],
      departments: [{ label: 'Todos', value: null }, { label: 'Primário', value: 'Primário' }],
      attendants: []
    },
    gauge: {
      count: 0,
      total: 0,
      average: 0
    }
  },
  async requestMatch () {
    let match = {
      accountId: this.account._id,
      cdt: {
        $gte: moment().subtract(6, 'days').startOf('day').format('YYYY-MM-DDTHH:mm:ss.sssZ'),
        $lte: moment().subtract(6, 'days').startOf('day').format('YYYY-MM-DDTHH:mm:ss.sssZ')
      }
    }
    let advancedSearch = await Utils.asyncClone(this.advancedSearch.match)
    let advancedOptions = await Utils.asyncClone(this.advancedSearch.options)
    advancedSearch.period = this.advancedSearch.match.period
    advancedSearch.cdt.$gte = this.advancedSearch.match.cdt.$gte
    advancedSearch.cdt.$lte = this.advancedSearch.match.cdt.$lte
    let user = await Utils.asyncClone(this.user)
    if (user.permission >= 13) {
      if (advancedSearch.ih.$in && advancedSearch.ih.$in.length > 0) {
        match.ih = {
          $in: advancedSearch.ih.$in.filter(f => f !== 'all')
        }
      } else {
        match.ih = {
          $in: []
        }
        await this.asyncForEach(advancedOptions.chatbots, each => {
          match.ih.$in.push(each.value)
        })
      }
    } else {
      if (advancedSearch.ih.$in && advancedSearch.ih.$in.length > 0) {
        match.ih = {
          $in: advancedSearch.ih.$in.filter(f => f !== 'all')
        }
      }
    }
    if (advancedSearch.period !== null) {
      advancedSearch.cdt.$gte = new Date()
      advancedSearch.cdt.$lte = new Date()
      if (advancedSearch.period && advancedSearch.period !== 1) {
        advancedSearch.cdt.$lte.setDate(advancedSearch.cdt.$lte.getDate())
      } else if (advancedSearch.period) {
        advancedSearch.cdt.$lte.setDate(advancedSearch.cdt.$lte.getDate() - 1)
      }
      if (advancedSearch.period == 99) {
        advancedSearch.cdt.$gte.setDate(new Date(advancedSearch.cdt.$gte.getFullYear(), advancedSearch.cdt.$gte.getMonth(), 1).getDate())
      } else if (advancedSearch.period) {
        if (advancedSearch.period === 1) {
          advancedSearch.cdt.$gte.setDate(advancedSearch.cdt.$gte.getDate() - advancedSearch.period)
        } else {
          advancedSearch.cdt.$gte.setDate(advancedSearch.cdt.$gte.getDate() - (advancedSearch.period - 1))
        }
      } else {
        advancedSearch.cdt.$gte.setDate(advancedSearch.cdt.$gte.getDate() - advancedSearch.period)
      }
      advancedSearch.cdt.$gte.setHours(0, 0, 0, 0)
      advancedSearch.cdt.$lte.setHours(23, 59, 59, 999)
    }

    match.cdt.$gte = moment(advancedSearch.cdt.$gte).format('YYYY-MM-DDTHH:mm:ss.sssZ')
    match.cdt.$lte = moment(advancedSearch.cdt.$lte).format('YYYY-MM-DDTHH:mm:ss.sssZ')

    // BEGIN: _ID
    if (advancedSearch.cResponse.match(/^[0-9a-fA-F]{24}$/)) {
      match._id = advancedSearch.cResponse
    } else if (advancedSearch.cResponse.match(/^[0-9]{13}$/)) {
      match.cdt = moment(parseInt(advancedSearch.cResponse)).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    } else {
      // BEGIN: C -> RESPONSE AND IDENTIFIERS
      if (advancedSearch.cResponse && advancedSearch.cResponse.length >= 2) {
        let cResponse = {}
        if (advancedSearch.cResponse[0] === ':') {
          let advancedIdentifierAndWord = advancedSearch.cResponse
          advancedIdentifierAndWord = advancedIdentifierAndWord.replace(':', '').split(' ')
          cResponse = {
            identifier: advancedIdentifierAndWord[0],
            $or: [{ resposta: { $regex: advancedIdentifierAndWord[1], $options: 'i' } }, { message: { $regex: advancedIdentifierAndWord[1], $options: 'i' } }]
          }
        } else if (advancedSearch.cResponseIdentifiers.length > 0) {
          cResponse = {
            identifier: { $in: advancedSearch.cResponseIdentifiers },
            $or: [{ resposta: { $regex: advancedSearch.cResponse, $options: 'i' } }, { message: { $regex: advancedSearch.cResponse, $options: 'i' } }]
          }
        } else {
          cResponse = { $or: [{ resposta: { $regex: advancedSearch.cResponse, $options: 'i' } }, { message: { $regex: advancedSearch.cResponse, $options: 'i' } }] }
        }
        match.$and = [
          {
            c: {
              $elemMatch: cResponse
            }
          }
        ]
      }
    }
    // BEGIN: C -> IDENTIFIERS
    if (advancedSearch.cIdentifiers.length > 0) {
      advancedSearch.cIdentifiers = advancedSearch.cIdentifiers.filter(f => f !== 'all')
      if (advancedSearch.cIdentifierFill) {
        if (!match.$and) {
          match.$and = []
        }
        await this.asyncForEach(advancedSearch.cIdentifiers, each => {
          match.$and.push({
            c: {
              $elemMatch: {
                identifier: each,
                resposta: { '$ne': '' },
                resposta: { '$ne': true }
              }
            }
          })
        })
      } else {
        if (!match.$or) {
          match.$or = []
        }
        await this.asyncForEach(advancedSearch.cIdentifiers, each => {
          match.$or.push({
            $nor: [
              {
                c: {
                  $elemMatch: {
                    identifier: each
                  }
                }
              }
            ]
          })
          match.$or.push({
            c: {
              $not: {
                $elemMatch: {
                  identifier: each,
                  resposta: { '$ne': '' },
                  resposta: { '$ne': true }
                }
              }
            }
          })
        })
      }
    }

    //BEGIN: MonetaryValue
    if (advancedSearch.monetaryValue && advancedSearch.monetaryValue.$gte !== '' && advancedSearch.monetaryValue.$lte !== '') {
      if (!match.$and) {
        match.$and = []
      }
      match.$and.push({
        'monetaryValue.value': {
          $gte: advancedSearch.monetaryValue.$gte,
          $lte: advancedSearch.monetaryValue.$lte
        }
      })
    } else if (advancedSearch.monetaryValue && advancedSearch.monetaryValue.$gte !== '') {
      if (!match.$and) {
        match.$and = []
      }
      match.$and.push({
        'monetaryValue.value': {
          $gte: advancedSearch.monetaryValue.$gte,
        }
      })
    } else if (advancedSearch.monetaryValue && advancedSearch.monetaryValue.$lte!== '') {
      if (!match.$and) {
        match.$and = []
      }
      match.$and.push({
        'monetaryValue.value': {
          $gte: 0,
          $lte: advancedSearch.monetaryValue.$lte
        }
      })
    }

    // BEGIN: CFinished
    if (advancedSearch.cFinished === 'Finalizadas' || advancedSearch.cFinished === 'Finished' || advancedSearch.cFinished === 'Finalizada') {
      if (!match.$and) {
        match.$and = []
      }
      match.$and.push({
        c: {
          $elemMatch: {
            exit: true
          }
        }
      })
    }

    if (advancedSearch.cFinished === 'Incompletas' || advancedSearch.cFinished === 'Finished' || advancedSearch.cFinished === 'Finalizada') {
      if (!match.$nor) {
        match.$nor = []
      }
      match.$nor.push({
        c: {
          $elemMatch: {
            exit: true
          }
        }
      })
    }

    // BEGIN: TAGS
    if (!advancedSearch.tags.includes('all') && advancedSearch.tags.length > 0) {
      let arrayTags = advancedSearch.tags.map(t => [t])
      match.$or = [{ tags: { $in: advancedSearch.tags } }, { tags: { $in: arrayTags } }]
    }
    // BEGIN: PERMISSION -> MANAGER
    if (user.permission === 13) {
      if (!match.$and) match.$and = []
      match.$and.push({
        $or: [
          { 'liveChat.department': { $in: user.department } },
          { 'zion.departments': { $in: user.department } },
          { 'zion.departments': { $size: 0 } },
          { 'liveChat.attendant': user.n },
          { 'zion.attendants': user._id },
          { 'zion': { $exists: false } }
        ]
      })
    }
    // BEGIN: PERMISSION -> ATTENDANTS
    let permission = await Utils.asyncClone(this.permission)
    let users = await Utils.asyncClone(this.users)
    if (user.permission >= 14) {
      if (permission.conversations[user.permission].view === 'all') {
        // DO NOTHING
      } else if (permission.conversations[user.permission].view === 'bots') {
        if (!match.$and) match.$and = []
        match.$and.push({
          $or: [
            { 'liveChat.attendant': user.n },
            { 'zion.attendants': user._id },
            { 'attendants.id': user._id }
          ]
        })
      } else if (permission.conversations[user.permission].view === 'only') {
        if (!match.$and) match.$and = []
        match.$and.push({
          $or: [
            { 'liveChat.attendant': user.n },
            { 'zion.attendants': user._id }
          ]
        })
      }
    } else {
      if (advancedSearch.attendants.length > 0) {
        let matchAttendants = {
          $or: [{
            'zion.attendants': {
              $in: advancedSearch.attendants
            }
          }]
        }
        await this.asyncForEach(advancedSearch.attendants, each => {
          if (users[advancedSearch.attendants[0]] && users[advancedSearch.attendants[0]].n) {
            matchAttendants.$or.push(
              {
                attendants: {
                  $elemMatch: {
                    name: users[advancedSearch.attendants[0]].n
                  }
                }
              }
            )
          }
        })
        if (match.$and) {
          match.$and.push(matchAttendants)
        } else {
          match.$and = [matchAttendants]
        }
      }
    }

    // BEGIN: LIVE
    if (advancedSearch.live !== null) {
      switch (advancedSearch.live) {
        case 1:
          if (!match.$and) {
            match.$and = []
          }
          match.$and.push({
            c: {
              $elemMatch: {
                inputType: 'live'
              }
            }
          })
          match['live.isConnected'] = false
          match['live.isResponded'] = false
          break
        case 2:
          match['live.isConnected'] = true
          if (match.$and) {
            match.$and.push({
              $or: [
                { 'live.isResponded': false },
                { 'live.isResponded': { $exists: false } }
              ]
            })
          } else {
            match.$and = [
              {
                $or: [
                  { 'live.isResponded': false },
                  { 'live.isResponded': { $exists: false } }
                ]
              }
            ]
          }
          break
        case 3:
          match['live.isConnected'] = true
          match['live.isResponded'] = true
          break
        case 4:
          if (!match.$and) {
            match.$and = []
          }
          match.$and.push({
            c: {
              $not: {
                $elemMatch: {
                  inputType: 'live'
                }
              }
            }
          })
          match['live.isConnected'] = false
          match['live.isResponded'] = false
          break
        case 5:
          break
      }
    }

    // BEGIN: RATING
    if (advancedSearch.rating.length > 0) {
      match.rating = {
        $in: advancedSearch.rating
      }
    }

    //BEGIN: KNOWLEDGEBASE
    if (advancedSearch.faqCategory && advancedSearch.faqCategory !== 'all') {
      match.faqCategory = advancedSearch.faqCategory
    } else {
      delete match.faqCategory
    }

    //BEGIN: GENAI
    if (advancedSearch.r && advancedSearch.r !== 'all') {
      match.r = advancedSearch.r
    } else {
      delete match.r
    }

    // BEGIN: DEPARTMENTS
    if (!advancedSearch.departments.includes('all') && advancedSearch.departments.length > 0) {
      let listDepartmentsOld = []
      await this.asyncForEach(advancedSearch.departments, async matchDepartment => {
        await this.asyncForEach(advancedOptions.departments, async optionDepartment => {
          if (matchDepartment === optionDepartment.value) {
            listDepartmentsOld.push(optionDepartment.label)
          }
        })
      })
      let matchDepartments = {
        $or: [{
          'zion.departments': {
            $in: advancedSearch.departments
          }
        }, {
          'liveChat.department': {
            $in: listDepartmentsOld
          }
        }]
      }
      if (match.$and) {
        match.$and.push(matchDepartments)
      } else {
        match.$and = [matchDepartments]
      }
    }

    // BEGIN: HB
    if (advancedSearch.hb) {
      switch (advancedSearch.hb) {
        case 'webchat':
          match.$nor = [
            {
              hb: 'Facebook'
            },
            {
              hb: 'Whatsapp'
            },
            {
              hb: 'Instagram'
            },
            {
              hb: 'Telegram'
            },
            {
              hb: 'GBM'
            },
            {
              hb: 'RCS'
            }
          ]
          break
        case 'facebook':
          match.hb = 'Facebook'
          break
        case 'whatsapp':
          match.hb = 'Whatsapp'
          break
        case 'instagram':
          match.hb = 'Instagram'
          break
        case 'telegram': case 'Telegram':
          match.hb = 'Telegram'
          break
        case 'gbm':
          match.hb = 'GBM'
          break
        case 'rcs':
          match.hb = 'RCS'
          break
      }
    }

    // BEGIN: DIALOGUE
    if (advancedSearch.dialogue) {
      if (advancedSearch.dialogue === 'active') {
        if (match.$and) {
          match.$and.push(
            {
              'c.1.isInjected': true
            }
          )
          match.$and.push(
            {
              'c.1.identifier': 'phone'
            }
          )
        } else {
          match.$and = [
            {
              'c.1.isInjected': true
            },
            {
              'c.1.identifier': 'phone'
            }
          ]
        }
      } else if (advancedSearch.dialogue === 'receptive') {
        if (match.$nor) {
          match.$nor.push(
            {
              'c.1.isInjected': true
            }
          )
        } else {
          match.$nor = [
            {
              'c.1.isInjected': true
            }
          ]
        }
      }
    }
    return match
  },
  title: '',
  bots: [],
  botsDeleted: [],
  botsName: {},
  botsId: {},
  botsRaw: {},
  botsById: {},
  botsSelectable: [],
  botsIdentifiers: {},
  bot: {},
  tags: {},
  tagsOptions: [],
  users: {},
  usersOptions: [],
  dark: false,
  token: null,
  localization: 'en-US',
  userInfo: {},
  user: {},
  account: {},
  zion: {
    status: -5
  },
  whatsapp: {
    versionOptions: [
      {
        label: 'Desabilitado',
        value: 0
      },
      {
        label: 'ChatAPI',
        value: 1
      },
      {
        label: 'Bubble',
        value: 2
      },
      {
        label: 'Gupshup',
        value: 3
      },
      {
        label: 'Twilio',
        value: 4
      },
      {
        label: 'Globalbot WhatsApp',
        value: 5
      }
    ]
  },
  liveAttendantSettings: {
    attendantBell: '',
    messageBell: '',
    sortType: -11,
    exhibition: false,
    exhibitionType: 1,
    exhibitionAlertTime: 1
  },
  liveSettings: {
    queue: {
      isActive: false,
      perAttendant: 2,
      distribution: 'none'
    },
    initiations: {
      email: false,
      sms: false,
      whatsapp: false,
      facebook: false
    },
    away: {
      isActive: false,
      options: []
    },
    awayAuto: {
      isActive: false,
      time: 5
    },
    messages: {
      finishRoom: 'A conversa foi finalizada.',
      archiveRoom: 'A conversa foi arquivada.',
      transferRoom: 'A conversa foi transferida.'
    }
  },
  liveDepartment: {},
  liveDepartments: [],
  liveDepartmentsOptions: [],
  department: {},
  socket: null,
  credits: 0,
  liveQuery: {
    isMaster: true,
    masterId: null,
    masterName: null,
    masterEmail: null,
    masterRole: null,
    masterAvatar: null,
    tokens: [],
    category: null,
    department: null
  },
  liveStatus: 1,
  preferences: {
    conversation: {},
    live: {}
  },
  audio: {
    isRecording: false
  },
  MASTERS_STATUS: {
    ONLINE: 5,
    AWAY: 3,
    OFFLINE: 1
  },
  permission: {
    _id: null,
    accountId: null,
    realtime: {
      10: {},
      11: {},
      12: {},
      13: {},
      14: {},
      15: {}
    },
    analytics: {
      10: {},
      11: {},
      12: {},
      13: {},
      14: {},
      15: {}
    },
    conversations: {
      10: {
        view: 'all'
      },
      11: {
        view: 'all'
      },
      12: {
        view: 'all'
      },
      13: {
        view: 'all'
      },
      14: {
        view: 'bots'
      },
      15: {
        view: 'bots'
      }
    },
    flow: {
      10: {},
      11: {},
      12: {},
      13: {},
      14: {},
      15: {}
    },
    knowledge: {
      10: {},
      11: {},
      12: {},
      13: {},
      14: {},
      15: {}
    },
    broadcast: {
      10: {},
      11: {},
      12: {},
      13: {},
      14: {},
      15: {}
    },
    live: {
      10: {},
      11: {},
      12: {},
      13: {},
      14: {},
      15: {}
    },
    library: {
      10: {},
      11: {},
      12: {},
      13: {},
      14: {},
      15: {}
    },
    settings: {
      10: {},
      11: {},
      12: {},
      13: {},
      14: {},
      15: {}
    }
  }
}
